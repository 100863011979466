<template>
  <v-snackbar v-model="isActive" :color="infoType" :multi-line="true" :timeout="timeout">
    {{ value ? (value.message ? value.message : value.content ? value.content : '') : '' }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="isActive = false"> Zamknij </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'InfoSnackBar',
    props: {
      value: {
        type: Object,
      },
    },
    data: () => ({
      //
    }),
    computed: {
      isActive: {
        get() {
          return !!this.value;
        },
        set() {
          this.$emit('input', null);
        },
      },
      infoType: {
        get() {
          switch (this.value?.status) {
            case 'error':
              return 'error';
            case 'success':
              return 'success';
            default:
              return '';
          }
        },
      },
      timeout: {
        get() {
          return this.value?.timeout || (this.value?.status == 'error' ? 4000 : 2000);
        },
      },
    },
    methods: {},
  };
</script>

<style>
</style>
